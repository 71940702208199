import uae from '../../../assets/img/icons/countries/svg/united-arab-emirates.svg'

import dubai from './dubai'

const country = {
  id: 'uae',
  slug: 'uae',
  name: 'Émirats arabes unis',
  images: {
    flag: uae
  },
  cities: [
    dubai
  ]
}

export default country