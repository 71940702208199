import dubai from '../../../../assets/img/stays/uae/dubai/city.jpg'

import gold from './gold'
import platinium from './platinium'

const city = {
  id: 'uae-dubai',
  slug: 'uae-dubai',
  name: 'Dubaï',
  description: 'Dubaï est une ville et un émirat des Émirats arabes unis réputé pour son shopping de luxe, son architecture ultramoderne et sa vie nocturne animée. La Burj Khalifa, tour de 830 mètres de haut, domine le paysage urbain parsemé de gratte-ciel. À son pied, la fontaine de Dubaï présente des jets et des lumières synchronisés avec de la musique. Atlantis, The Palm est un hôtel comprenant parcs aquatiques avec animaux marins situé au large, sur des îles artificielles.',
  images: {
    background: dubai
  },
  stays: [
    gold,
    platinium
  ]
}

export default city