import React from "react"

import StayPage from '../../../../containers/stay'

import stayData from '../../../../data/staysFull/uae/dubai/gold'
import similar1 from '../../../../data/staysFull/uae/dubai/platinium'
import similar2 from '../../../../data/staysFull/thailand/phuket/diamond'

import country from '../../../../data/staysFull/uae'
import city from '../../../../data/staysFull/uae/dubai'

const DataStayPage = ({ location }) => {
  return (
    <StayPage stayData={ stayData } similarsData={[similar1, similar2]} country={country} city={city} pathname={location.pathname} />
  )
}

export default DataStayPage
