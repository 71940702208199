import React from "react"

import background from '../../../../../assets/img/stays/uae/dubai/city.jpg'
/* places */
import burjalarab from '../../../../../assets/img/stays/uae/dubai/gold/burjalarab.webp'
import burjkhalifa from '../../../../../assets/img/stays/uae/dubai/gold/burjkhalifa.webp'
import mosquee from '../../../../../assets/img/stays/uae/dubai/gold/mosquee.webp'
import palmAtlantis from '../../../../../assets/img/stays/uae/dubai/gold/palm-atlantis.webp'
import palm from '../../../../../assets/img/stays/uae/dubai/gold/palm.webp'
import street from '../../../../../assets/img/stays/uae/dubai/gold/street.webp'
/* activities includes */
import zerogravity from '../../../../../assets/img/stays/uae/dubai/gold/zerogravity.webp'
import sand from '../../../../../assets/img/stays/uae/dubai/gold/sand.webp'
import barasti from '../../../../../assets/img/stays/uae/dubai/gold/barasti.webp'
import sand2 from '../../../../../assets/img/stays/uae/dubai/gold/sand2.webp'
import burjkhalifaview from '../../../../../assets/img/stays/uae/dubai/gold/burjkhalifaview.webp'
/* activities not includes */
import ski from '../../../../../assets/img/stays/uae/dubai/gold/ski.webp'
import ferrari from '../../../../../assets/img/stays/uae/dubai/gold/ferrari.webp'
import montgolfiere from '../../../../../assets/img/stays/uae/dubai/gold/montgolfiere.webp'
import roue from '../../../../../assets/img/stays/uae/dubai/gold/roue.webp'
import globalvillage from '../../../../../assets/img/stays/uae/dubai/gold/globalvillage.webp'
import aquaventure from '../../../../../assets/img/stays/uae/dubai/gold/aquaventure.webp'
/* hotel */
import bar from '../../../../../assets/img/stays/uae/dubai/gold/bar.webp'
import food from '../../../../../assets/img/stays/uae/dubai/gold/food.webp'
import pool from '../../../../../assets/img/stays/uae/dubai/gold/pool.webp'
import room from '../../../../../assets/img/stays/uae/dubai/gold/room.webp'
import sport from '../../../../../assets/img/stays/uae/dubai/gold/sport.webp'
import work from '../../../../../assets/img/stays/uae/dubai/gold/work.webp'
/* room */
import double from '../../../../../assets/img/stays/uae/dubai/gold/double.jpg'
import twin from '../../../../../assets/img/stays/uae/dubai/gold/twin.jpg'
/* days */
import uae from '../../../../../assets/img/icons/countries/svg/united-arab-emirates.svg'
import france from '../../../../../assets/img/icons/countries/svg/france.svg'
/* airport */
import cdg from '../../../../../assets/img/stays/uae/dubai/gold/cdg.webp'
import dxb from '../../../../../assets/img/stays/uae/dubai/gold/dxb.webp'

const stay = {
  id: 'uae-dubai-gold',
  slug: 'uae-dubai-gold',
  name: 'Dubaï Gold',
  description: 'Un séjour inoubliable à Dubaï accompagné de 10 autres indépendants dans un hôtel 4 étoiles',
  descriptionHead: <span>Un séjour inoubliable dans un hôtel 4 étoiles</span>,
  descriptionLong: 'Un séjour inoubliable à Dubaï accompagné de 10 autres indépendants dans un hôtel 4 étoiles. Pendant ce séjour tu vas pouvoir faire pleins d\'activités extras ! Mais tu pourras également travailler et échanger avec d\'autres indépendants. Tout cela où ? Simplement dans l\'une des plus belles villes du monde, Dubaï ! Que tu aimes les buildings, le désert ou encore la plage Dubaï est fait pour toi. Tu peux même skier alors que demander de plus ? N\'attend plus et participe à un séjour de 10 jours tout simplement inoubliable.',
  url: '/stay/uae/dubai/gold',
  images: {
    background: background,
    activities: [
      sand,
      burjkhalifaview,
      barasti
    ],
    hotel: [
      room,
      pool,
      work,
      sport,
      food,
      bar
    ],
    room: {
      double: double,
      twin: twin
    },
    resume: [//common
      zerogravity,
      room,
      mosquee,
      work,
      ski,
      pool,
      sand2,
      palm
    ]
  },
  checkLists: [
    {
      name: 'Inclus ? Pas inclus ?',
      infos: '* Départ au choix depuis Paris, Lyon, Lille, Marseille, Toulouse, Bordeaux, Nantes ou Montpellier',
      lists: [
        {
          status: 'true',
          items: [
            "L'aller-retour depuis l'un des principaux aéroports de France*",
            "Les transferts entre l'hôtel et l'aéroport de Dubaï",
            "Une chambre dans un hôtel 4 étoiles (9 nuits)",
            "9 petits-déjeuners (1 chaque jour)",
            "Une salle de travail du lundi au vendredi",
            "7 repas",
            "Une entrée pour le BeachClub Zero Gravity",
            "Une entrée pour le 125ème étage de la Burj Khalifa",
            "Un safari dans le désert",
            "Le transport pour chaque activité"
          ]
        },
        {
          status: 'false',
          items: [
            "Le transport entre chez toi et l'aéroport en France",
            "Toutes les activités que tu souhaites faire en plus",
            "Tous les déplacements non prévues pour le groupe"
          ]
        }
      ]
    }
  ],
  program: [
    {
      name: 'Vendredi',
      image: uae,
      title: 'Dubaï nous voilà !',
      content: {
        text: 'C\'est le grand jour ! Rendez-vous à l\'aéroport pour rejoindre les autres participants au séjour. Décollage de l\'aéroport en France et atterrissage à celui de Dubaï avec 6h de vol. Ensuite, on vient te chercher avec ton groupe et direction l\'hôtel pour que tu puisses t\'installer dans ta chambre 🛫',
        images: [cdg, dxb, room]
      } 
    },
    {
      name: 'Samedi',
      image: zerogravity,
      title: 'Plage + Piscine + Sable + Soleil = BeachClub',
      content: {
        text: 'On commence bien ce séjour avec une journée dans l\'un des plus célèbres beach club de Dubaï, Zero Gravity ! Au programme, piscines, plage et musique. On se détend dans l\'eau 🏝 et ensuite on grimpe en haut de la plus haute tour du monde, la Burj Khalifa 🏢',
        images: [zerogravity]
      } 
    },
    {
      name: 'Dimanche',
      image: mosquee,
      title: 'Journée rien que pour toi',
      content: {
        text: 'Cette journée elle est pour toi. Une activité que tu veux faire en particulier ? Un lieu à visiter ? Comme tu voudras, tu es libre 🙃',
        images: [ski, ferrari, roue]
      }
    },
    {
      name: 'Lundi - Vendredi',
      image: work,
      title: 'Le travail c\'est bien, au soleil c\'est mieux',
      content: {
        text: 'Rien de spécifique, simplement une salle de travail à ta disposition avec ton groupe pour travailler dans un cadre idyllique 💻',
        images: [food, work, pool]
      }
    },
    {
      name: 'Samedi',
      image: mosquee,
      title: 'Journée rien que pour toi',
      content: {
        text: 'Cette journée elle est pour toi. Une activité que tu veux faire en particulier ? Un lieu à visiter ? Comme tu voudras, tu es libre 🙃',
        images: [ski, ferrari, roue]
      }
    },
    {
      name: 'Dimanche',
      image: sand,
      title: 'On a du sable dans les chaussures',
      content: {
        text: 'Le matin c\'est détente, tu profites de l\'hôtel et ensuite tu pars avec ton groupe pour une après-midi de folie ! Au programme les dunes en 4x4, du snowboard sur le sable et un tour à dos de dromadaire. Tu finis ensuite la journée avec un repas typique, en plein désert, au coin du feu 🏜',
        images: [sand, sand2]
      }
    },
    {
      name: 'Lundi',
      image: france,
      title: 'Retour à Paris',
      content: {
        text: 'On revient du safari en se rendant directement à l\'aéroport, décollage de l\'aéroport de Dubaï et atterrissage à celui de Paris avec 6h de vol 🛬',
        images: [dxb, cdg]
      }
    }
  ],
  infos: [
    {
      name: 'Hôtel',
      description: 'Un hôtel 4* entre la Palm de Dubaï et la Burj Khalifa. Parfait pour être proche de tout.',
      images: [
        room,
        pool,
        work,
        sport,
        food,
        bar
      ]
    },
    {
      name: 'Activités',
      description: 'C\'est simple, à Dubaï tu peux tout faire ! Nous avons donc dû faire un choix.',
      images: [
        sand,
        burjkhalifaview,
        barasti
      ]
    },
    {
      name: 'Activités Optionnelles',
      description: 'Mais comme on pense à toi, voici la liste (non exhaustive) de ce que tu peux faire en plus.',
      images: [
        ski,
        ferrari,
        montgolfiere,
        roue,
        globalvillage,
        aquaventure
      ]
    },
    {
      name: 'Destinations',
      description: 'Ici c\'est une petite liste des immeubles, souks, plages, ...',
      images: [
        street,
        palmAtlantis,
        mosquee,
        burjkhalifa,
        palm,
        burjalarab,
      ]
    }
  ],
  faq: [
    {
      question: 'Depuis où puis-je prendre l\'avion ? Quelle est la durée du trajet ?',
      answer: 'Il est possible de partir depuis Paris, Lyon, Lille, Marseille, Toulouse, Bordeaux, Nantes ou Montpellier. Si tu pars depuis Paris il n\'y a aura qu\'un seul vol qui durera environ 6h.'
    },
    {
      question: 'Combien de valise je peux prendre ?',
      answer: 'Le billet d\'avion est prévu pour un bagage cabine et un bagage à main.'
    },
    {
      question: 'Peux-t-on réserver pour plusieurs personnes ?',
      answer: 'Il faut faire une réservation distincte pour chaque participant.'
    },
    {
      question: 'Combien de personne par séjour ?',
      answer: 'Vous serez entre 8 et 12 indépendants par séjour.'
    },
    {
      question: 'Quand ferme les réservations ?',
      answer: 'Les réservations ferment 20 jours avant la date de départ prévue.'
    },
    {
      question: 'Que se passe-t-il si il y a moins de 10 personnes qui on réservé avant la cloture ?',
      answer: 'Si lors de la cloture des réservations le groupe est composé de moins de 10 personnes alors nous te contacterons afin de te proposer un séjour similaire à la même date. Si aucun séjour de te correspond nous te fournirons un avoir afin que tu puisses participer à ce séjour sur une autre date.'
    },
    {
      question: 'Que se passe-t-il si la covid-19 empêche le séjour ?',
      answer: 'Si la covid-19 empêche le départ pour Dubaï alors nous te contacterons afin de te proposer un séjour similaire à la même date. Si aucun séjour de te correspond nous te fournirons un avoir afin que tu puisses participer à ce séjour sur une autre date.',
      covid: true
    },
    {
      question: 'Quelles sont les conditions pour participer à un séjour ?',
      answer: 'Il te suffit d\'avoir plus de 18 ans à la date de départ et d\'avoir un passeport valide jusque 6 mois après la date de retour prévue.'
    },
    {
      question: 'Quelles sont les conditions liés à la covid-19 pour participer à un séjour ?',
      answer: 'Il faudra simplement que tu puisses justifier, à l\'arriver, d\'un test PCR négatif effectué moins de 72h avant.',
      covid: true
    },
    {
      question: 'Que se passe-t-il si je suis testé positif à la covid-19 avant mon départ ?',
      answer: 'Dans le cas où votre test PCR est positif alors nous annulerons ton séjour et te fournirons un avoir équivalent à 75% du prix de ton séjour annulé.',
      covid: true
    }
  ],
  place: {
    name: 'Al Barsha 1',
    map: <iframe title="dubai-al-barsha" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=Al+Barsha+1+-+Dubaï&amp;ie=UTF8&amp;t=&amp;z=12&amp;iwloc=B&amp;output=embed"></iframe>
  },
  datas: {
    stars: 4,
    category: 'gold',
    starsName: 'Hôtel 4 étoiles',
    price: 1980,
    days: 10,
    nights: 9,
    recommand: true
  }
}

export default stay